import "./Footer.css";
import React, { useState } from "react";
import { Button, Input, Form, message } from "antd";
import { FaBars, FaTimes } from "react-icons/fa";
import { ArrowRightOutlined } from "@ant-design/icons";
// import img1 from "../images/img1.png";
import { FiFacebook } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaPinterestP } from "react-icons/fa";
import { useForm } from "antd/lib/form/Form";
import { Link } from "react-router-dom";
function Footer() {
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [form] = useForm();
  const onFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "subscribed",
      attributeValue: [values],
    });
    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.statusCode === 200) {
          message.success("Subscribed Successfully");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="footer-page"
        id="footer"
      >
        <div className="container">
          {/* <div>
            <div className="footer-logo-section">
              <div className=" align-self-end">
                <img className="img-fluid" src={img1} />
              </div>
              <div className="footer-number">
                <span>+91 844114063</span>
              </div>
            </div>
          </div> */}
          <div className="d-md-flex">
            <div className="col-md-7 d-sm-flex">
              <div className="footer-first col-md-5 col-sm-6 ">
                <div
                  data-aos="fade-up"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                  className="footer-para-icon "
                >
                  <span className="">
                    <a
                      href="https://www.facebook.com"
                      className="contact-icon-item"
                      target="_blank"
                    >
                      {" "}
                      <FiFacebook />{" "}
                    </a>
                  </span>
                  <span className="px-2">
                    <a
                      href="https://twitter.com"
                      className="contact-icon-item"
                      target="_blank"
                    >
                      {" "}
                      <BsTwitter />{" "}
                    </a>
                  </span>
                  <span className="px-2">
                    <a
                      href="https://in.pinterest.com"
                      className="contact-icon-item"
                      target="_blank"
                    >
                      <FaPinterestP />
                    </a>
                  </span>
                  <span className="px-2">
                    {" "}
                    <a
                      href="https://www.instagram.com"
                      className="contact-icon-item"
                      target="_blank"
                    >
                      {" "}
                      <AiOutlineInstagram />{" "}
                    </a>
                  </span>
                </div>
                <div className="footer-heading-mail mt-4">
                  {" "}
                  <Link to="/Aboutus" spy={true} offset={0}>
                    {" "}
                    <p>About Us</p>
                  </Link>
                  <Link to="/Services3" spy={true} offset={0}>
                    {" "}
                    <p>Services</p>
                  </Link>
                  <Link to="/Condition3" spy={true} offset={0}>
                    {" "}
                    <p>Conditions</p>
                  </Link>
                  <Link to="/Contactnew" spy={true} offset={0}>
                    {" "}
                    <p>Contact Us</p>
                  </Link>
                </div>
              </div>

              <div className="col-md-6 mx-md-5">
                <div className="footer-heading-mail  ">
                  <h3
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Location I
                  </h3>
                  <p className="mt-4">
                    Floor: 1st Suite: 1C, 288 N Broad St, <br />
                    Elizabeth, NJ 07208, United States.
                    <br /> +1 908-820-0007
                  </p>
                </div>
                <div className="footer-heading-two ">
                  <h3
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Working Hours
                  </h3>
                  <p className="mt-4">
                    Monday : 09 : 30 am - 06 : 00 pm<br/>
                    Wednesday : 09 : 30 am - 06 : 00 pm<br/>
                   Friday : 09 : 30 am - 06 : 00 pm
             
                  </p>

                  <br />
                </div>
              </div>
              <div className="col-md-6 mx-md-5">
                <div className="footer-heading-mail  ">
                  <h3
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Location II
                  </h3>
                  <p className="mt-4">
                    576 Central Ave # L4, East Orange, <br />
                    NJ 07018, United States
                    <br /> +1 973-676-0100
                  </p>
                </div>
                <div className="footer-heading-two ">
                  <h3
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                  >
                    Working Hours
                  </h3>

                  <p className="mt-4">
                    Tuesday, Thursday  : 09 : 30 am – 06 : 00 pm <br />
                   Wednesday: 02 : 00 pm – 06 : 00 pm
                    <br /> Saturday : 09 : 00 am – 12 : 00 pm
                  </p>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
