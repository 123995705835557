import React, { useState } from "react";
import { Modal, Input, InputNumber, Form, Button, message } from "antd";
import "./ContactUs.css";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
export default function ContactUs({ category, setCategory }) {
  const [page, setPage] = useState("");
  const navigate = useNavigate();
  const PRIMARY_API =
    "https://p7l8yu5pnl.execute-api.ap-south-1.amazonaws.com/dev";
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "OBOTECHNOLOGY",
      modName: "UPDATE_CONTACTS",
      TableName:"PHYSICAL_THERAPY_REVIEWS",
      contents:{
        id:uuidv4(),
        ...values
      }
    });
    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="contact-main-page   " id="contactus">
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="contact-page container"
        >
          <div className="text-center ">
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              CONTACT US
            </h1>
          </div>
          <div className="">
            {" "}
            <Form
              form={form}
              onFinish={handleFinish}
              className="contactus-form  "
              layout="vertical"
              // data-aos="fade-up"
              // data-aos-easing="ease-in-sine"
              // data-aos-delay="500"
            >
              <div className=" d-lg-flex justify-content-between mt-5">
                <div className="col-12 col-lg-6 ">
                  <div
                    data-aos="fade-up "
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="300"
                    className="contact-page-box  "
                  >
                  Name 
                    <Form.Item
                      className=""
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First name!",
                        },
                      ]}
                    >
                      <Input placeholder="" className="phone" />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-lg-6 mt-0">
                  <div
                    data-aos="fade-up "
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="300"
                    className="contact-page-box  "
                  >
                    Email
                    <Form.Item
                    
                      className=""
                      name="lastname"
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: "Please input your Mail Id!",
                        },
                      ]}
                    >
                      <Input placeholder="" className="" />
                    </Form.Item>
                  </div>
                </div>
                {/* <div
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="300"
                    className="col my-lg-0 my-3"
                  >
                    <div className="contact-social-icons">
                      <div
                        data-aos="fade-up"
                        data-aos-easing="ease-in-sine"
                        data-aos-delay="100"
                        className="contact-para-icon "
                      >
                        <p
                          data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          className="contact-page-para3"
                        >
                          Find us here
                        </p>
                        <span className="">
                          <SiFacebook />
                        </span>
                        <span className="">
                          {" "}
                          <AiFillTwitterCircle />
                        </span>
                        <span>
                          {" "}
                          <IoLogoLinkedin />{" "}
                        </span>
                      </div>
                    </div>
                  </div> */}
              </div>
              <div className="col-12 col-lg-6 ">
                {" "}
                <div className="contact-page-box  mt-5">
                  Message
                  <Form.Item
                    className="where  pt-5"
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Message For Us!",
                      },
                    ]}
                  >
                    <TextArea rows={2} placeholder=" " />
                  </Form.Item>
                  <Form.Item className=" contact-btn pt-4 ">
                    <Button htmlType="submit">Submit</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </section>
      </main>
    </div>
  );
}
