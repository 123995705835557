import "./Navbarbutton.css";
import React, { useState } from "react";
import { Button, Drawer, Radio, Space } from "antd";
import { HiMenuAlt4 } from "react-icons/hi";
import img11 from "../images/img11.png";
import { Link } from "react-router-dom";
import Ani from "../Ani/Ani";
import { FiFacebook } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { SlSocialLinkedin } from "react-icons/sl";
import { FiYoutube } from "react-icons/fi";
const Navbarbutton = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const ToggleNavBar = () => {
    let element = document.getElementsByClassName("navbar-toggler")[0];
    if (element.getAttribute("aria-expanded") == "true") {
      element.click();
    }
  };
  return (
    <>
      <div className=" d-flex justify-content-between container ">
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="navbar-para-icon  "
        >
          {" "}
          <h1 className="heading">TOTAL CARE PHYSICAL THERAPY</h1>
          {/* <span className="">
            <FiFacebook />
          </span>
          <span className="px-2">
            {" "}
            <BsTwitter />
          </span>
          <span className="px-2">
            <SlSocialLinkedin />
          </span>
          <span className="px-2">
            {" "}
            <FiYoutube />{" "}
          </span> */}
        </div>{" "}
        <div className="Navbarbutton-page  ">
          <div className="Navbarbutton-page-button" onClick={showDrawer}>
            <img className="img-fluid" src={img11} />
          </div>{" "}
          {/* <Button className="Navbarbutton-page-button" onClick={showDrawer}>
          <HiMenuAlt4 />
        </Button> */}
          <Drawer
            className="into"
            placement={placement}
            onClose={onClose}
            open={open}
            key={placement}
            footer={false}
          >
            <Ani onClose={onClose} open={open} setOpen={setOpen} />

            {/* <div className="hr-line1">
            <hr></hr>
          </div> */}
          </Drawer>
        </div>
      </div>
    </>
  );
};
export default Navbarbutton;
{
  /* <div class="header">


<div class="inner-header flex">

<svg version="1.1" class="logo" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" xml:space="preserve">
<path fill="#FFFFFF" stroke="#000000" stroke-width="10" stroke-miterlimit="10" d="M57,283" />
<g><path fill="#fff"
d="M250.4,0.8C112.7,0.8,1,112.4,1,250.2c0,137.7,111.7,249.4,249.4,249.4c137.7,0,249.4-111.7,249.4-249.4
C499.8,112.4,388.1,0.8,250.4,0.8z M383.8,326.3c-62,0-101.4-14.1-117.6-46.3c-17.1-34.1-2.3-75.4,13.2-104.1
c-22.4,3-38.4,9.2-47.8,18.3c-11.2,10.9-13.6,26.7-16.3,45c-3.1,20.8-6.6,44.4-25.3,62.4c-19.8,19.1-51.6,26.9-100.2,24.6l1.8-39.7		c35.9,1.6,59.7-2.9,70.8-13.6c8.9-8.6,11.1-22.9,13.5-39.6c6.3-42,14.8-99.4,141.4-99.4h41L333,166c-12.6,16-45.4,68.2-31.2,96.2	c9.2,18.3,41.5,25.6,91.2,24.2l1.1,39.8C390.5,326.2,387.1,326.3,383.8,326.3z" />
</g>
</svg>

</div>


<div>
<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>
<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>
<g class="parallax">
<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
</g>
</svg>
</div>


</div> */
}
