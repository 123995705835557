import React, { Component } from "react";


import "./Typing.css";
const Typing = () => (
<p className="la">
  Spice up your type with CSS
  <span className="sp">
    Animated text fill
  </span>

</p>
);

export default Typing;
