import React, { Component } from "react";


import "./Head.css";
const Head = () => (
<div class="container-name">
  <h2 class="title">
    <span class="title-word title-word-1">  GREAT PLACE <br />
              TO GET <br />
              THERAPY</span>
   
  </h2>
</div>
);

export default Head;
