import React, { useState } from "react";
import {
  Modal,
  Input,
  InputNumber,
  Form,
  Button,
  message,
  Select,
  DatePicker,
} from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./Contact.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

const serviceData = [
  {
    Gender: "Female",
    Doctor: "Doctor1",
    Department: "Department1",
  },
  {
    Gender: "Male",
    Doctor: "Doctor2",
    Department: "Department2",
  },
  {
    Doctor: "Doctor3",
    Department: "Department3",
  },
];
export default function Contact({ category, setCategory }) {
  const [page, setPage] = useState("");
  const navigate = useNavigate();
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "",
      attributeValue: [values],
    });
    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="contact-main-page   " id="contact">
        <section className="con-page container">
          <div className="text-center">
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              Book your appointment
            </h1>
          </div>
          <Form
            form={form}
            onFinish={handleFinish}
            className="contact-form"
            layout="vertical"
            // data-aos="fade-up"
            // data-aos-easing="ease-in-sine"
            // data-aos-delay="500"
          >
            <div className="d-lg-flex justify-content-center">
              <div className="col-12 col-md-12  m-1 ">
                <div className=" mx-3 p-5 ">
                  <div className="d-md-flex justify-content-between">
                    <div className="contact-page-box ">
                      <Form.Item
                        label="Name"
                        name="Name"
                        rules={[
                          {
                            message: "Please input your Name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item className="where mt-4" name="Gender">
                        Gender{" "}
                        <Select placeholder="">
                          {serviceData.map((e, i) => (
                            <Select.Option value={e.Gender}>
                              {e.Gender}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        className="where my-3"
                        name="time"
                        rules={[
                          {
                            message: "Please input your Time!",
                          },
                        ]}
                      >
                        Date
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        className="where mt-3 "
                        name="Doctor "
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please input your Service!",
                        //   },
                        // ]}
                      >
                        Doctor{" "}
                        <Select placeholder="">
                          {serviceData.map((e, i) => (
                            <Select.Option value={e.Doctor}>
                              {e.Doctor}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="contact-page-box ">
                      <Form.Item
                        className=""
                        name="Email"
                        rules={[
                          {
                            type: 'email',
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        Email
                        <Input placeholder="" className="" />
                      </Form.Item>

                      <Form.Item
                        className="my-3"
                        name="PhoneNumber"
                        rules={[
                          {
                            message: "Please input your PhoneNumber!",
                          },
                        ]}
                      >
                        PhoneNumber
                        <Input placeholder="" className="" />
                      </Form.Item>
                      <Form.Item
                        className="where my-3"
                        name="time"
                        rules={[
                          {
                            message: "Please input your Time!",
                          },
                        ]}
                      >
                        Time
                        <TimePicker
                          onChange={onChange}
                          defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                        />
                      </Form.Item>
                      <Form.Item
                        className="where mt-3"
                        name="Department"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please input your Service!",
                        //   },
                        // ]}
                      >
                        Department{" "}
                        <Select placeholder="">
                          {serviceData.map((e, i) => (
                            <Select.Option value={e.Department}>
                              {e.Department}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item
                    className="where my-3 "
                    name="message"
                    rules={[
                      {
                        message: "Please Enter Your Message For Us!",
                      },
                    ]}
                  >
                    Message
                    <TextArea rows={3} placeholder=" " />
                  </Form.Item>
                  <Form.Item className=" contact-btn pt-4 ">
                    <Button htmlType="submit">Submit</Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </section>
      </main>
    </div>
  );
}
