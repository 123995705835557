import React, { Component, useState } from "react";
import "../App.css";
import ServicesHero3 from "./ServicesHero3/ServicesHero3";
import Contact from "./Contact/Contact";
export default function Reviews({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="Reviews">
      <ServicesHero3 />
      <Contact />
    </div>
  );
}
