import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Ani.css";

const Ani = ({ open, setOpen, onClose }) => (
  <div className="header">
    <div className="inner-header flex">
      <div
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="main-navlink-page text-center "
      >
        <div className="main-navlink-page-first my-3">
          <Link onClick={onClose} to="/">
            HOME
          </Link>
        </div>
        <div className="main-navlink-page-second my-3">
          <Link onClick={onClose} to="/Aboutus">
            ABOUT US
          </Link>
        </div>
        <div className="main-navlink-page-fourth my-3">
          <Link onClick={onClose} to="/Services3">
            SERVICES
          </Link>
        </div>
        <div className="main-navlink-page-fourth my-3">
          <Link onClick={onClose} to="/Condition3">
            CONDITIONS
          </Link>
        </div>
        <div className="main-navlink-page-third my-3">
          <Link onClick={onClose} to="/Contactnew">
            CONTACT US
          </Link>
        </div>

        {/* <div className="main-navlink-page-fifth my-3">
          <Link onClick={onClose} to="/ContactUs1">
            CONTACT US
          </Link>
        </div> */}
      </div>
    </div>

    <div>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
  </div>
);
export default Ani;
