import "./Services2.css";
import { Rate } from "antd";
import { Input, Button, Drawer, Form, message } from "antd";
import { Button as AntButton } from "antd";
import { Link } from "react-router-dom";
import img71 from "../images/img71.jpg";
import img14 from "../images/img14.jpg";
import img15 from "../images/img15.jpg";
import img16 from "../images/img16.jpg";
import img24 from "../images/img24.jpg";
import img18 from "../images/img18.jpg";
import img19 from "../images/img19.jpg";
import img20 from "../images/img20.jpg";

import { ArrowRightOutlined } from "@ant-design/icons";
export default function Services2() {
  const data = [
    {
      name: "EXERCISE",
      image: img71,
      amount: "20,000/-",
      type: "The Training of the body to improve its function and enhance its fitness. ",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart1" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "ULTRASOUND",
      image: img14,
      amount: "20,000/-",
      type: "Ultrasound is a deep heating treatment used to treat many musculoskeletal conditions like sprains, strains, or tendonitis. ",
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart2" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "ICE",
      image: img15,
      amount: "20,000/-",
      type: " Ice is usually used during the acute or initial phase of injury to limit localized swelling around tissues.",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart8" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "HEAT",
      image: img20,
      amount: "20,000/-",
      type: "Effective heat therapy products typically maintain a relatively warm – not piping hot – temperature. ",
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart7" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },

    {
      name: "JOINT MOBILIZATION",
      image: img24,
      amount: "20,000/-",
      type: "Joint mobilization occurs when your physical therapist passively moves the joints of your body in specific directions.  ",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart4" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "WHIRLPOOL",
      image: img18,
      amount: "20,000/-",
      type: "Whirlpools are a form of hydrotherapy and are used to help improve circulation, maintain clean wounds, or control inflammation.  ",
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart5" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: " KINESIOLOGY TAPING",
      image: img19,
      amount: "20,000/-",
      type: "Kinesiology taping, or K-tape, is often used by physical therapists to augment your rehab program.",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart6" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "TRACTION",
      image: img16,
      amount: "20,000/-",
      type: "Traction is used in the treatment of low back pain and neck pain to help decrease pain and improve mobility in the spine.",
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Services2-request-button-yellow ">
          <Link to="/Aboutpart3" spy={true} offset={0}>
            <Button className="Services2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
  ];
  return (
    <div className=" main-Services2-page" id="services2">
      <div className="container">
        <div className=" Services2-page-heading text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            BETTER
            <br /> INFORMATION
            <br />
            BETTER HEALTH
          </h1>
        </div>
        <div className="row px-0 content-pack ">
          {data.map((e) => (
            <div key={e + Math.random()} className={`col-md-3 ${e.margin}`}>
              <div className="mx-0">
                <div
                  data-aos="fade-down"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                  className="d-lg-flex justify-content-between w-100"
                >
                  <div className="Services2-name">{e.name}</div>
                </div>
                <div className="Services2-image-box">
                  <img className="img-fluid hover-img " src={`${e.image}`} />
                </div>
              </div>
              <div className=" mt-3">
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  className="Services2-type"
                >
                  {e.type}
                </div>
              </div>
              <div className=" mt-3">
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  className="Services2-type"
                >
                  {e.apply}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div class="container d-flex justify-content-end mt-1 ">
          <div className="Services2-box">
            <div className=""> Services2</div>
            <div className="ms-2">
              <AntButton
                type="primary"
                icon={<ArrowRightOutlined />}
              ></AntButton>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
