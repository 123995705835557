import React, { Component } from "react";
import { Button } from "antd";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import img5 from "../images/img5.png";
import img6 from "../images/img6.png";
import img10 from "../images/img10.png";
import img29 from "../images/img29.png";
import { BsArrowRight } from "react-icons/bs";

import "./About.css";
const About = () => (
  <div>
    <main
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
      className="main-about-page"
      id="nature"
    >
      <section className="about-page container">
        <div className=" about-page-heading text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            LOVE AND COMPASSION
          </h1>
        </div>

        <div className="d-md-flex justify-content-center">
          <div className=" col-lg-4 col-md-4 about-page-image1">
            <div className="mx-2">
              <div className="img1-image-box">
                <img className="img-fluid  hover-img" src={img1}></img>{" "}
              </div>
              <div className="img1-image-box">
                <img className="img-fluid hover-img" src={img4}></img>
              </div>
              <div className="img1-image-box">
                {" "}
                <img className="img-fluid hover-img" src={img10}></img>
              </div>
            </div>
          </div>
          <div className=" col-lg-4 col-md-4 about-page-image2 ">
            <div className="mx-2">
              <div className="img1-image-box">
                {" "}
                <img className="img-fluid hover-img" src={img2}></img>
              </div>
              <div className="img1-image-box">
                <img className="img-fluid hover-img" src={img5}></img>
              </div>
              <div className="img1-image-box">
                {" "}
                <img className="img-fluid hover-img" src={img29}></img>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 about-page-image3 ">
            <div className="mx-2 ">
              <div className="img1-image-box">
                <img className="img-fluid hover-img" src={img3}></img>
              </div>
              <div className="img1-image-box">
                <img className="img-fluid hover-img" src={img6}></img>{" "}
              </div>
              <div className="img1-image-box">
                <img className="img-fluid hover-img" src={img10}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default About;
