import React, { Component } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import "./Weare.css";
// import Typing from "../Typing/Typing";
const Weare = () => (
  <div>
    <main
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
      className="main-Weare-page"
      id="nature"
    >
      <section className="Weare-page container">
        <div className=" Weare-page-heading text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            WE ARE
          </h1>
          <p className="mt-5">
            A physical therapist is someone who treats people (of all ages) who
            have muscular skeletal & nurological conditions or other
            health-related conditions that limits their ability to function and
            move properly in their daily lives.
          </p>
        </div>
        <div className="text-center mt-5">
          <span className="Weare-btn  ">
            <Button type="primary" key="console">
              <Link to="/Aboutus">Read More</Link>
            </Button>
          </span>
        </div>
      </section>
    </main>
  </div>
);

export default Weare;
