import React, { Component, useState,useEffect } from "react";
import "../App.css";

import ServicesHero3 from "./ServicesHero3/ServicesHero3";
import Contactpage from "./Contactpage/Contactpage";
export default function Contactnew({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
 },[])
  return (
    <div id="Contactnew">
      <ServicesHero3 />
      <Contactpage />
    </div>
  );
}
