import React, { Component } from "react";

import img41 from "../images/img41.jpg";
import img49 from "../images/img49.jpg";
import img50 from "../images/img50.jpg";
import img51 from "../images/img51.jpg";
import img52 from "../images/img52.jpg";
import img53 from "../images/img53.jpg";
import img54 from "../images/img54.jpg";
import img55 from "../images/img55.jpg";
import "./Aboutpart13.css";
const Aboutpart13 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart13-page"
      id="nature"
    >
      <section className="Aboutpart13-page container">
        <h2 className="col-md-9 pt-5  text-end">FOOT / ANKLE</h2>
        <div className="d-md-flex   align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-md-4">
            <h1>Sprains/Strains</h1>
            <p className="mt-5">
              The ankle joint is a complicated joint and there are many bones
              which come together to make up the ankle joint. There are many
              ligaments that attach to the bones at the ankle and are very
              susceptible to injury.
            </p>
          </div>

          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img41}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-md-4">
            <h1> Fractures</h1>
            <p className="mt-5">
              Fractures are full or partial breaks in a bone. Those that exist
              in the foot or ankle can be commonly recognized and treated
              together. In regards to an ankle fracture, the tibia (commonly
              known as the shinbone).
            </p>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img49}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className=" col-md-4 imp-page px-md-3">
            <h1> Tendinitis</h1>
            <p className="mt-5">
              Your feet and ankles are the foundation of your mobility. So,
              whether you work or play on your feet, pain in this area can give
              you significant setbacks in your daily life.
            </p>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img50}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1 imp-page px-md-3">
            <h1>Shin Splints</h1>
            <p className="mt-5">
              Medial tibial stress syndrome, more commonly termed shin splints,
              is the term used for lower leg pain that occurs below the knee
              over the anterior portion of the tibia.
            </p>
          </div>{" "}
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img51}
            ></img>
          </div>
        </div>

        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1">
            <div className="imp-page px-md-3">
              <h1>Stress Fractures</h1>
              <p className="mt-5">
                A stress fracture in the foot or ankle is a small crack or
                severe bruising on a bone. It is commonly seen in athletes who
                participate in running sports, such as soccer or basketball.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img52}
            ></img>
          </div>{" "}
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-12 col-md-4 m-1">
            <h1> heel spur</h1>
            <p className="mt-5">
              A heel spur is when a calcium deposit causes an outgrowth on the
              calcaneus bone, or heel bone. They can occur on the back of the
              heel, under the heel, or beneath the sole of the foot.
            </p>
          </div>{" "}
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img53}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-12 col-md-4 m-1">
            <h1>Plantar Fasciitis Stretches</h1>
            <p className="mt-5">
              The sole of your foot is covered by a fascia. This blankets the
              muscles of the sole of your foot, from front to back. This
              covering can be inflamed for various reasons.
            </p>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img54}
            ></img>
          </div>{" "}
        </div>
        <div className="d-md-flex align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-12 col-md-4 m-1">
            <h1>Ankle laxity</h1>
            <p className="mt-5">
              Chronic Ankle laxity is instability of the ankle joint.
              Instability occurs because of previous ankle injuries. The
              ligaments of the ankle do not provide full support because of
              previous sprains and strains.
            </p>
          </div>{" "}
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img55}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart13;
