import React, { Component } from "react";

import img39 from "../images/img39.jpg";
import img58 from "../images/img58.jpg";
import img59 from "../images/img59.jpg";
import "./Aboutpart11.css";
const Aboutpart11 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart11-page"
      id="nature"
    >
      <section className="Aboutpart11-page container">
        <h2 className=" col-md-9 pt-5  text-end">HAND / WRIST</h2>
        <div className="d-md-flex   align-items-center justify-content-center pt-5">
          <div className=" col-12 col-md-4 m-1 imp-page px-md-3">
            <h1 className=""> Arthritis</h1>
            <p className="mt-5">
              Arthritis is a disease that affects the joints where two or more
              bones meet and as the cartilage breaks down results in a loss of
              range of motion.
            </p>
          </div>
          <div className="col-12 col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img39}
            ></img>
          </div>
        </div>

        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className=" col-12 col-md-4 m-1 imp-page px-md-3">
            <h1> carpal tunnel</h1>
            <p className="mt-5">
              The carpal tunnel is a small anatomical passageway created by the
              arch-like arrangement of the small bones that make up your wrist.
              A tough ligament called the transverse carpal ligament forms the
              roof of this tunnel.{" "}
            </p>
          </div>

          <div className="col-12 col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img58}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className=" col-12 col-md-4 m-1 imp-page px-md-3">
            <h1>Fractures</h1>
            <p className="mt-5">
              Fractures are full or partial breaks in a bone. Those that exist
              in the foot or ankle can be commonly recognized and treated
              together.
            </p>
          </div>
          <div className="col-12 col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img59}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart11;
