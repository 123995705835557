import React, { Component, useState,useEffect} from "react";
import "../App.css";

import ServicesHero3 from "./ServicesHero3/ServicesHero3";

import Aboutpart1 from "./Aboutpart1/Aboutpart1";
import Aboutpart2 from "./Aboutpart2/Aboutpart2";
import Aboutpart3 from "./Aboutpart3/Aboutpart3";
import Aboutpart4 from "./Aboutpart4/Aboutpart4";
import Aboutpart6 from "./Aboutpart6/Aboutpart6";
import Aboutpart5 from "./Aboutpart5/Aboutpart5";
import Aboutpart7 from "./Aboutpart7/Aboutpart7";
import Aboutpart8 from "./Aboutpart8/Aboutpart8";
export default function Services3({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
 },[])
  return (
    <div id="Services3">
      <ServicesHero3 />
      <Aboutpart1 />
      <Aboutpart2 />
      <Aboutpart8 />
      <Aboutpart7 />

      <Aboutpart4 />

      <Aboutpart5 />
      <Aboutpart6 />
      <Aboutpart3 />
    </div>
  );
}
