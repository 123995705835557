import React, { Component } from "react";

import img38 from "../images/img38.jpg";

import img60 from "../images/img60.jpg";
import img61 from "../images/img61.jpg";
import img62 from "../images/img62.jpg";
import "./Aboutpart10.css";
const Aboutpart10 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart10-page"
      id="nature"
    >
      <section className="Aboutpart10-page container">
        <h2 className="col-md-9 pt-5  text-end">ELBOW</h2>
        <div className="d-md-flex   align-items-center justify-content-center  mt-5">
          <div className="col-12 col-md-4  imp-page px-md-3">
            <h1> Dislocation</h1>
            <p className="mt-5">
              An elbow dislocation happens when either or both of the forearm
              bones (the radius and ulna) are moved out of alignment with the
              upper arm bone (the humerus).
            </p>
          </div>
          <div className="col-12 col-md-8 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img62}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center mt-5">
          <div className="  col-md-4 imp-page px-md-3">
            <h1> Bicep Tendon Rupture Treatment</h1>
            <p className="mt-5">
              The bicep muscle is the muscle that is located in the front of
              your upper arm. The biceps are attached at the top of the shoulder
              and below the elbows by a strong tendon.
            </p>
          </div>

          <div className="col-12 col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img60}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="col-md-4 imp-page px-md-3">
            <h1> Epicondylitis</h1>
            <p className="mt-5">
              Tennis elbow, also known as lateral epicondylitis is an
              inflammation of the muscles that connect to the outer portion of
              your elbow.
            </p>
          </div>
          <div className="col-12 col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img61}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="col-12 col-md-4 m-1 imp-page px-md-3">
            <h1> Fractures</h1>
            <p className="mt-5">
              The elbow is one of the body’s most critical joints, affecting
              every motion we make with our arms. A fractured elbow, therefore,
              can put us out of commission for many of the activities.
            </p>
          </div>
          <div className="col-12 col-md-8 mx-1 super px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img38}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart10;
