import React, { Component } from "react";

import img43 from "../images/img43.jpg";

import "./Aboutpart15.css";
const Aboutpart15 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart15-page"
      id="nature"
    >
      <section className="Aboutpart15-page container">
        <h2 className="col-md-9 pt-5  text-end">NECK</h2>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className=" col-12 col-md-4 imp-page px-md-3">
            <h1>Herniated Cervical Disc</h1>
            <p className="mt-5">
              When a cervical disc becomes herniated, the disc can press on
              nerves and cause pain, numbness and tingling. A disc can be
              damaged by a fall or accident, repeated straining of the neck,
              lifting weights, violent twisting and can occur without any
              specific mechanism of injury.
            </p>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img43}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart15;
