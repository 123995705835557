import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import Home from "./Components/Home";
import Aboutus from "./Components/Aboutus";
import Services3 from "./Components/Services3";
import Head from "./Components/Head/Head";
import Condition3 from "./Components/Conditions3";
import Navbarbutton from "./Components/Navbarbutton/Navbarbutton";
import Contact from "./Components/Contact/Contact";
import Contactpage from "./Components/Contactpage/Contactpage";
import Reviews from "./Components/Reviews";
import Contactnew from "./Components/Contactnew";
import SuccessComponent from "./Components/SuccessComponent/SuccessComponent";
import Conditions2 from "./Components/Conditions2/Conditions2";
import Aboutpart1 from "./Components/Aboutpart1/Aboutpart1";
import Aboutpart2 from "./Components/Aboutpart2/Aboutpart2";
import Aboutpart3 from "./Components/Aboutpart3/Aboutpart3";
import Aboutpart4 from "./Components/Aboutpart4/Aboutpart4";
import Aboutpart5 from "./Components/Aboutpart5/Aboutpart5";
import Aboutpart6 from "./Components/Aboutpart6/Aboutpart6";
import Aboutpart7 from "./Components/Aboutpart7/Aboutpart7";
import Aboutpart8 from "./Components/Aboutpart8/Aboutpart8";
import Aboutpart9 from "./Components/Aboutpart9/Aboutpart9";
import Aboutpart10 from "./Components/Aboutpart10/Aboutpart10";
import Aboutpart11 from "./Components/Aboutpart11/Aboutpart11";
import Aboutpart12 from "./Components/Aboutpart12/Aboutpart12";
import Aboutpart13 from "./Components/Aboutpart13/Aboutpart13";
import Aboutpart14 from "./Components/Aboutpart14/Aboutpart14";
import Aboutpart15 from "./Components/Aboutpart15/Aboutpart15";
import Aboutpart16 from "./Components/Aboutpart16/Aboutpart16";
import Typing from "./Components/Typing/Typing";
import Flipping from "./Components/Flipping/Flipping";
import Footer from "./Components/Footer/Footer";
import { useParams } from "react-router-dom";
const Wholeapp = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newValues, setNewValues] = useState({});

  let { id } = useParams();
  return (
    <Router>
      {/* <Navbar /> */}
      <Navbarbutton />
      <Routes>
        <Route path="/">
          <Route
            exact={true}
            index
            element={
              <Home
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Aboutus">
          <Route
            exact={true}
            index
            element={
              <Aboutus
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Services3">
          <Route
            exact={true}
            index
            element={
              <Services3
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Condition3">
          <Route
            exact={true}
            index
            element={
              <Condition3
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Reviews">
          <Route
            exact={true}
            index
            element={
              <Reviews
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route path="/Contactnew">
          <Route
            exact={true}
            index
            element={
              <Contactnew
                setModalVisible={setModalVisible}
                setNewMessage={setNewMessage}
                newValues={newValues}
                setNewValues={setNewValues}
              />
            }
          />
        </Route>
        <Route
          path="Success"
          element={<SuccessComponent newValues={newValues} />}
        />
        <Route path="Contact" element={<Contact />} />
        <Route path="Conditions2" element={<Conditions2 />} />
        <Route path="Contactpage" element={<Contactpage />} />
        <Route path="Aboutpart1" element={<Aboutpart1 />} />
        <Route path="Aboutpart2" element={<Aboutpart2 />} />
        <Route path="Aboutpart3" element={<Aboutpart3 />} />
        <Route path="Aboutpart4" element={<Aboutpart4 />} />
        <Route path="Aboutpart5" element={<Aboutpart5 />} />
        <Route path="Aboutpart6" element={<Aboutpart6 />} />
        <Route path="Aboutpart7" element={<Aboutpart7 />} />
        <Route path="Aboutpart8" element={<Aboutpart8 />} />
        <Route path="Aboutpart9" element={<Aboutpart9 />} />
        <Route path="Aboutpart10" element={<Aboutpart10 />} />
        <Route path="Aboutpart11" element={<Aboutpart11 />} />
        <Route path="Aboutpart12" element={<Aboutpart12 />} />
        <Route path="Aboutpart13" element={<Aboutpart13 />} />
        <Route path="Aboutpart14" element={<Aboutpart14 />} />
        <Route path="Aboutpart15" element={<Aboutpart15 />} />
        <Route path="Aboutpart16" element={<Aboutpart16 />} />
        <Route path="Head" element={<Head />} />
        <Route path="Typing" element={<Typing />} />
        <Route path="Flipping" element={<Flipping />} />
      </Routes>
      <Footer />
    </Router>
  );
};
export default Wholeapp;
