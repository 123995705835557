import React, { Component } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import "./Background.css";
const Background = () => (
  <div>
    <div class="home-section" id="home">
      <div class="home-content">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
);

export default Background;
