import React, { Component } from "react";

import img37 from "../images/img37.jpg";
import img63 from "../images/img63.jpg";
import img64 from "../images/img64.jpg";
import img65 from "../images/img65.jpg";
import img66 from "../images/img66.jpg";
import img67 from "../images/img67.jpg";
import img68 from "../images/img68.jpg";
import "./Aboutpart9.css";
const Aboutpart9 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart9-page"
      id="nature"
    >
      <section className="Aboutpart9-page container">
        <h4 className=" pt-5 text-center">CONDITIONS</h4>
       <h2 className=" col-md-9 pt-5  text-end">BACK</h2>
        <div className="d-md-flex  align-items-center justify-content-center mt-5">
          <div className="imp-page px-md-3  col-md-4  ">
            <h1>Disc Herniation</h1>
            <p className="mt-5">
              When a disc becomes herniated, the disc can press on nerves and
              cause pain, numbness and tingling. A disc can be damaged by a fall
              or accident, repeated straining of the back, lifting weights.
            </p>
          </div>
          <div className=" col-md-8 mx-1 super3 px-md-3">
         
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img37}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="imp-page px-md-3  col-md-4 ">
            <div className="imp-page px-md-3">
              <h1> Degenerative Disc Disease</h1>
              <p className="mt-5">
                Degenerative disc disease (DDD) is an extremely prevalent cause
                of back pain, immobility, and decreased quality of life. it can
                cause back pain and neuropathic pain elsewhere in the body which
                can be very severe.
              </p>
            </div>
          </div>
          <div className=" col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img63}
            ></img>
          </div>
        </div>
        <div className="d-md-flex align-items-center justify-content-center  mt-5">
          <div className="imp-page px-md-3  col-md-4 m-1">
            <h1> Scoliosis, kyphosis, spinal deformity</h1>
            <p className="mt-5">
              Spinal deformity is an abnormal alignment or curve of the bony
              vertebral column. Adult scoliosis and kyphosis can be caused by
              age-related wear and tear on the back or complications from past
              surgeries.
            </p>
          </div>
          <div className=" col-md-8 mx-1  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img64}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="imp-page px-md-3  col-md-4 m-1">
            <h1> Spinal stenosis, sciatica, radiculopathy</h1>
            <p className="mt-5">
              Spinal stenosis, or the abnormal narrowing of the spinal canal,
              occurs in close to 8% of people around the world. Treatment is
              available, and can provide relief both quickly and consistently.
            </p>
          </div>{" "}
          <div className=" col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img65}
            ></img>
          </div>
        </div>

        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="imp-page px-md-3  col-md-4 m-1">
            <div className="imp-page px-md-3">
              <h1> Spine Deformities</h1>
              <p className="mt-5">
                The spine consists of a complex system of vertebrae in the back
                and neck and discs that protect the spinal cord, an
                all-important part of our nervous system.
              </p>
            </div>
          </div>
          <div className=" col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img66}
            ></img>
          </div>{" "}
        </div>
        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="imp-page px-md-3  col-md-4 m-1">
            <h1> Spinal trauma</h1>
            <p className="mt-5">
              Spinal trauma is caused by damage to any part of the spinal cord
              that results in a contusion, a partial tear, or a complete tear. A
              traumatic spinal cord injury may occur due to a sudden blow or cut
              to the spine.
            </p>
          </div>{" "}
          <div className=" col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img67}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center  mt-5">
          <div className="imp-page px-md-3  col-md-4 m-1">
            <h1> Spinal Fractures</h1>
            <p className="mt-5">
              A broken or fractured bone is always a serious health matter. But
              spinal fractures are especially a cause for concern. Not only does
              this result in immediate pain and lessened mobility.
            </p>
          </div>
          <div className=" col-md-8 mx-1 super3  px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img68}
            ></img>
          </div>{" "}
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart9;
