import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import img21 from "../images/img21.jpg";
import img90 from "../images/img90.jpg";
// import Ani1 from "../Ani1/Ani1";
import { useNavigate } from "react-router-dom";
import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Aboutpart1.css";
const Aboutpart1 = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <main
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="Aboutpart1-page"
        id="nature"
      >
        <section className="Aboutpart1-page container">
          <h1 className="text-center mt-5">SERVICES</h1>
          <div className="d-md-flex justify-content-center align-items-center pt-5">
            <div className="col-12 col-md-4   ">
              <div className="imp-page text-left">
                <h3> EXERCISE</h3>
                <p className="mt-5">
                  The Training of the body to improve its function and enhance
                  its fitness. Passive exercise is one that requires you to
                  simply relax while another person, like a physical therapist,
                  applies the stress.
                </p>

                {/* <div className="About1-request-button-yellow">
                  <Button
                    data-aos="fade-down"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="100"
                    className="About1-request-button mt-3"
                    type="primary"
                    key="console"
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                  >
                    Book Your Trip
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-md-7 mx-1 super3 px-md-3 ">
              <img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="100"
                className="img-fluid hover-img"
                src={img90}
              ></img>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Aboutpart1;
