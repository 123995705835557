import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import video from "../videos/video.mp4";
import "./Hero.css";
import Background from "../Background/Background";
import { AiOutlineMinus } from "react-icons/ai";
import Head from "../Head/Head";
const Hero = () => (
  <div className="main-hero-page ">
    <Background />
    <div className="container">
      <div className="d-lg-flex align-items-center justify-content-center hero-video-outerbox">
        <div className="hero-video-box order-md-1 order-0  col-lg-6 ">
          <video className="img-fluid" autoplay="autoplay" loop="true" muted>
            <source src={video} type="video/mp4"></source>
          </video>
        </div>
      </div>
      <section className="hero-page-contentbox">
        <div className="">
          {" "}
          <div className="hero-page-content text-center">
            {/* <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              GREAT PLACE <br />
              TO GET <br />
              THERAPY
            </h1> */}
            <Head/>
          </div>
          {/* <div className="text-center ">
            <span className="hero-btn text-end ">
              <Button type="primary" key="console">
             
                  {" "}
                  Book an Appointment
               
              </Button>
            </span>
          </div> */}
        </div>
      </section>
    </div>
  </div>
);

export default Hero;
