import React, { Component, useState,useEffect} from "react";
import "../App.css";

import ServicesHero3 from "./ServicesHero3/ServicesHero3";

import Whoarewe from "./Whoarewe/Whoarewe";
import Whoarewe1 from "./Whoarewe1/Whoarewe1";
import Whoarewe2 from "./Whoarewe2/Whoarewe2";
export default function Aboutus({

}) {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
 },[])
  return (
    <div id="Aboutus">
      <ServicesHero3 />
      <Whoarewe />
      <Whoarewe1 />
      <Whoarewe2 />
    </div>
  );
}
