import React, { Component } from "react";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import img16 from "../images/img16.jpg";
import img17 from "../images/img17.jpg";
import img18 from "../images/img18.jpg";
import img33 from "../images/img33.png";
import img15 from "../images/img15.jpg";
// import Ani1 from "../Ani1/Ani1";

import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Whoarewe.css";

const Whoarewe = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="side-whoarewe-page" id="nature">
        <section className="Whoarewe-page container">
          <h1 className="text-center mt-5">ABOUT US</h1>
          <div className="d-lg-flex justify-content-center">
            <div className="whoarewe-img">
              <img
                className=" col-12 col-lg-6 m-1 img-fluid m-md-4 "
                data-aos="fade-up"
                data-aos-delay="200"
                src={img33}
              />
            </div>
            <div className="col-12 col-lg-6 mx-5 ">
              <h3>MANUAL THERAPY YOUR BODY NEEDS</h3>
              <p>
                Customized manual treatments using all of our skills and tools -
                geared toward what you need the most. Our therapists are focused
                on you for the entirety of the treatment. This is best utilized
                for patients who want to continue to get better after Physical
                Therapy, or athletes that are working to maintain peak condition
                during training or after the competition.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Whoarewe;
