import "./Conditions2.css";
import { Rate } from "antd";
import { Input, Button, Drawer, Form, message } from "antd";
import { Button as AntButton } from "antd";
import { Link } from "react-router-dom";
import img80 from "../images/img80.jpg";
import img81 from "../images/img81.jpg";
import img83 from "../images/img83.jpg";
import img84 from "../images/img84.jpg";
import img85 from "../images/img85.jpg";
import img87 from "../images/img87.jpg";
import img88 from "../images/img88.jpg";
import img89 from "../images/img89.jpg";

import { ArrowRightOutlined } from "@ant-design/icons";
export default function Conditions2() {
  const data = [
    {
      name: "BACK",
      image: img80,
      amount: "20,000/-",
      type: (
        <ul>
          <li>Disc Herniation</li>
          <li>Degenerative disc disease</li>
          <li> Scoliosis, kyphosis, spinal deformity </li>
        </ul>
      ),
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart9" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },

    {
      name: "ELBOW",
      image: img81,
      amount: "20,000/-",
      type: (
        <ul>
          <li>Dislocation</li>
          <li> Biceps Tendon Rupture</li>
          <li> Epicondylitis ( Tennis Elbo)</li>
        </ul>
      ),
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart10" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "HAND/WRIST",
      image: img83,
      amount: "20,000/-",
      type: (
        <ul>
          <li>Arthritis</li>
          <li> Carpal Tunnel</li>
          <li> Fractures</li>
        </ul>
      ),

      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart11" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "HIP",
      image: img84,
      amount: "20,000/-",

      type: (
        <ul>
          <li>Bursitis</li>
          <li> Groin Strain</li>
          <li> Fractures</li>
        </ul>
      ),
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart12" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },

    {
      name: "FOOT/ANKLE",

      image: img85,
      amount: "20,000/-",
      type: (
        <ul>
          <li>Sprains/Strains</li>
          <li> Tendonitis</li>
          <li> Fractures</li>
        </ul>
      ),
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart13" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "KNEE",
      image: img87,
      amount: "20,000/-",

      type: (
        <ul>
          <li>Meniscal Tear</li>
          <li> Patella Femoral Syndrome</li>
          <li> Damage to Anterior Cruciate Ligament</li>
        </ul>
      ),
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart14" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: " NECK",
      image: img88,
      amount: "20,000/-",
      type: (
        <ul>
          <li>Herniated Cervical Disc</li>
          <li> Fractures</li>
        </ul>
      ),
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart15" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
    {
      name: "SHOULDER",
      image: img89,
      amount: "20,000/-",

      type: (
        <ul>
          <li>Rotator Cuff Tear</li>
          <li> Frozen Shoulder</li>
          <li> Shoulder Impingement Syndrome</li>
        </ul>
      ),
      margin: "mt-md-5 mt-0 pt-md-5 pt-0",
      apply: (
        <span className="Conditions2-request-button-yellow ">
          <Link to="/Aboutpart16" spy={true} offset={0}>
            <Button className="Conditions2-request-button" type="primary ">
              Read More
            </Button>
          </Link>
        </span>
      ),
    },
  ];
  return (
    <div className=" main-Conditions2-page" id="services2">
      <div className="container">
        <div className=" Conditions2-page-heading text-center">
          <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
            Conditions
          </h1>
        </div>
        <div className="row px-0 content-pack ">
          {data.map((e) => (
            <div key={e + Math.random()} className={`col-md-3 ${e.margin}`}>
              <div className="mx-0">
                <div
                  data-aos="fade-down"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="100"
                  className="d-lg-flex justify-content-between w-100"
                >
                  <div className="Conditions2-name">{e.name}</div>
                </div>
                <div className="Conditions2-image-box">
                  <img className="img-fluid hover-img " src={`${e.image}`} />
                </div>
              </div>
              <div className=" mt-3">
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  className="Conditions2-type"
                >
                  {e.type}
                </div>
              </div>
            
              <div className=" mt-3">
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  className="Conditions2-type"
                >
                  {e.apply}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div class="container d-flex justify-content-end mt-1 ">
          <div className="Services2-box">
            <div className=""> Services2</div>
            <div className="ms-2">
              <AntButton
                type="primary"
                icon={<ArrowRightOutlined />}
              ></AntButton>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
