import React, { Component } from "react";
import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import img73 from "../images/img73.png";
import { useState } from "react";
// import Ani1 from "../Ani1/Ani1";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import "./Aboutpart3.css";
const Aboutpart3 = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();
  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });
    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-delay="100"
        className="Aboutpart3-page"
        id="nature"
      >
        <section className="Aboutpart3-page container">
          <div className="d-md-flex justify-content-center align-items-center pt-5">
            <div className="col-12 col-md-4 m-1  ">
              <div className="imp-page text-left">
                <h3> TRACTION</h3>
                <p className="mt-5">
                  Traction is used in the treatment of low back pain and neck
                  pain to help decrease pain and improve mobility in the spine.
                  To use lumbar traction, you must be strapped into a mechanical
                  machine.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-7 mx-1 super3 px-md-3">
              <img
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="200"
                className="img-fluid prop"
                src={img73}
              ></img>
            </div>
          </div>
        </section>
        {/* <Drawer
          className="Services1-page-modal "
          title=""
          open={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          footer={false}
        >
          {" "}
          <div className="outer-component-box">
            {" "}
            <Ani1 onClose={onClose} open={open} setOpen={setOpen} />
            <div className="form-component-box mt-4">
              <Form form={form} onFinish={handleFinish}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input
                    className="Services1-page-input my-0"
                    placeholder="NAME HERE*"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    className="Services1-page-input my-0"
                    placeholder="PHONE NUMBER*"
                  />
                </Form.Item>
                <Form.Item
                  name="place"
                  rules={[
                    { required: true, message: "Please input your place!" },
                  ]}
                >
                  <Input
                    className="Services1-page-input my-0"
                    placeholder="PLACE*"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    className="Services1-page-input my-0"
                    placeholder="EMAIL ID*"
                  />
                </Form.Item>
                <Form.Item
                  className="where-time "
                  name="time"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Time!",
                    },
                  ]}
                >
                  <DatePicker
                    className=" my-0"
                    placeholder="TRAVEL DATE"
                    showTime
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input the description!",
                    },
                  ]}
                >
                  <Input
                    className=" Services-page-input my-0"
                    placeholder="TELL US ABOUT YOUR TRIP PLAN"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="Aboutpage-request-button-yellow ">
                    <Button
                      className="Aboutpage-request-button"
                      style={{ float: "right" }}
                      htmlType="submit"
                      type="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Drawer> */}
      </main>
    </div>
  );
};

export default Aboutpart3;
