import React, { Component } from "react";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import img16 from "../images/img16.jpg";
import img17 from "../images/img17.jpg";
import img18 from "../images/img18.jpg";
import img35 from "../images/img35.png";
import img15 from "../images/img15.jpg";
// import Ani1 from "../Ani1/Ani1";
import img32 from "../images/img32.png";
import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Whoarewe2.css";

const Whoarewe2 = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="side-Whoarewe2-page" id="nature">
        <section className="Whoarewe2-page container">
          <div className="d-lg-flex justify-content-center">
            <div className="Whoarewe2-img">
              <img
                className=" col-12 col-lg-6  img-fluid m-md-4 "
                data-aos="fade-up"
                data-aos-delay="200"
                src={img35}
              />
            </div>
            <div className="col-12 col-lg-6  ">
              <h3>CRYOTHERAPY + COMPRESSION</h3>
              <p>
                No tool is more potent at delivering these two recovery
                techniques in combination than Game Ready. One Nine is proud to
                utilize Game Ready in treatments and as an a-la-carte service.
                This machine is used in nearly all professional sports and
                collegiate training rooms to aid in recovery, support therapy,
                and speed recovery for athletes.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Whoarewe2;
