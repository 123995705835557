import React from "react";
import { Button } from "antd";
// import video from "../videos/video.mp4";
import "./ServicesHero3.css";

const ServicesHero3 = () => (
  <div>
    <main
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
      className="main-ServicesHero3-page"
      id="nature"
    >
      {" "}
      <div className="ServicesHero3-page-content text-left">
        {" "}
        <div className="ServicesHero3-main-title container">
          {/* We Love to Exceed Our Expectations */}
        </div>
      </div>
      <section className="ServicesHero3-page container">
        <div>
          {/* <div className="hero-video-box order-md-1 order-0">
            <video className="" autoplay="autoplay" loop="true" muted>
              <source src={video} type="video/mp4"></source>
            </video>
          </div> */}
          {/* 
          <div className="ServicesHero-page-content text-left">
            <p
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
              className="ServicesHero-page-para1"
            >
              ABOUT US{" "}
            </p>
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              SERVING THE WORLD AROUND US
            </h1>
            <br></br>
          </div> */}
        </div>
      </section>
    </main>
  </div>
);

export default ServicesHero3;
