import React, { useState } from "react";
import { Modal, Input, InputNumber, Form, Button, message } from "antd";
import "./Contactpage.css";
import { useNavigate } from "react-router-dom";
import { FiFacebook } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaPinterestP } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { SiFacebook } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
export default function Contactpage({ category, setCategory }) {
  const [page, setPage] = useState("");
  const navigate = useNavigate();
  const PRIMARY_API =
    "https://p7l8yu5pnl.execute-api.ap-south-1.amazonaws.com/dev";
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "OBOTECHNOLOGY",
      modName: "UPDATE_CONTACTS",
      TableName: "PHYSICAL_THERAPY_REVIEWS",
      contents: {
        id: uuidv4(),
        ...values
      }
    });
    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="Contactpage-main-page" id="Contactpage">
        <section
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="100"
          className="Contactpage-page container"
        >
          <h4
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            className="text-center"
          >
            CONTACT US
          </h4>
          <div className="text-start">
            <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
              CONTACT FORM:
            </h1>
          </div>

          <Form
            form={form}
            onFinish={handleFinish}
            className="contact-form"
            layout="vertical"
          // data-aos="fade-up"
          // data-aos-easing="ease-in-sine"
          // data-aos-delay="500"
          >
            <div className="d-lg-flex justify-content-between">
              <div className="col-12 col-lg-4 m-1 ">
                <div
                  data-aos="fade-up "
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="300"
                  className="Contactpage-page-box  "
                >
                  <Form.Item
                    className="Contactpage-page-box"
                    name="Name"
                    rules={[
                      {
                        message: "Please input your Name!",
                      },
                    ]}
                  >
                    Name
                    <Input />
                  </Form.Item>
                  {/* <Form.Item
                    className="Contactpage-page-box"
                    name="Email"
                    rules={[
                      {
                        type: 'email',
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    Email
                    <Input placeholder="" className="" />
                  </Form.Item> */}

                  <Form.Item
                   className="where my-3 Contactpage-page-box"
                    name="email"
                   
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },

                    ]}
                  >
                    Email
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="where my-3 Contactpage-page-box"
                    name="message"
                    rules={[
                      {
                        message: "Please Enter Your Message For Us!",
                      },
                    ]}
                  >
                    Message
                    <TextArea rows={3} placeholder=" " />
                  </Form.Item>
                  <Form.Item className=" contact-btn pt-4 ">
                    <Button htmlType="submit">Submit</Button>
                  </Form.Item>
                </div>
              </div>

              <div
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
                className="col-12 col-md-6 Contactpage-page-big-textarea mx-md-5"
              >
                <div>
                  <div className="Contactpage-page-visit ">
                    <h1 data-aos="fade-right" data-aos-easing="ease-in-sine">
                      Address I
                    </h1>

                    <p
                      data-aos="fade-right"
                      data-aos-easing="ease-in-sine"
                      className="Contactpage-page-visit-address1 "
                    >
                      <span className="">
                        Floor: 1st Suite: 1C, 288 N Broad St, <br />
                        Elizabeth, NJ 07208, United States.
                        <br /> +1 908-820-0007
                      </span>
                    </p>
                    <br></br>
                    <div className="Contactpage-page-line">
                      <h5 data-aos="fade-right" data-aos-easing="ease-in-sine">
                        Address II
                      </h5>
                      <p
                        data-aos="fade-right"
                        data-aos-easing="ease-in-sine"
                        className="Contactpage-page-visit-address2 "
                      >
                        <span className="">
                          576 Central Ave # L4, East Orange, <br />
                          NJ 07018, United States
                          <br /> +1 973-676-0100
                        </span>
                      </p>
                      <br></br>
                    </div>
                  </div>

                  <div
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="300"
                    className="col my-lg-0 my-3"
                  >
                    <div className="Contactpage-social-icons">
                      <div
                        data-aos="fade-up"
                        data-aos-easing="ease-in-sine"
                        data-aos-delay="100"
                        className="Contactpage-para-icon "
                      >
                        <p
                          data-aos="fade-right"
                          data-aos-easing="ease-in-sine"
                          className="Contactpage-page-para3"
                        >
                          Find us here
                        </p>
                        <span className="">
                          {/* <a
                            href="https://www.facebook.com"
                            className="contact-icon-item"
                            target="_blank"
                          >
                            {" "} */}
                          <FiFacebook />{" "}
                          {/* </a> */}
                        </span>
                        <span className="px-2">
                          {/* <a
                            href="https://twitter.com/"
                            className="contact-icon-item"
                            target="_blank"
                          > */}
                          {" "}
                          <BsTwitter />{" "}
                          {/* </a> */}
                        </span>
                        <span className="px-2">
                          <a
                            href="https://in.pinterest.com/"
                            className="contact-icon-item"
                            target="_blank"
                          >
                            <FaPinterestP />
                          </a>
                        </span>
                        <span className="px-2">
                          {" "}
                          {/* <a
                            href="https://www.instagram.com/accounts/login/"
                            className="contact-icon-item"
                            target="_blank"
                          > */}
                          {" "}
                          <AiOutlineInstagram />{" "}
                          {/* </a> */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex ">
                    {" "}
                    <div className="lastContactpage-heading mt-5">
                      <h5 data-aos="fade-right" data-aos-easing="ease-in-sine">
                        Location I
                      </h5>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12104.934804903034!2d-74.2160339!3d40.6688194!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6c6c29cb6be27353!2sTotal%20Care%20Physical%20Therapy!5e0!3m2!1sen!2sin!4v1669963704982!5m2!1sen!2sin"
                        width="150"
                        height="80"
                      ></iframe>
                    </div>
                    <div className="lastContactpage-heading mt-5 px-3">
                      <h5 data-aos="fade-right" data-aos-easing="ease-in-sine">
                        Location II
                      </h5>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12088.168302416674!2d-74.2235269!3d40.7610993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x741560896a333518!2sTotal%20Care%20Physical%20Therapy%20Center%20P.C.!5e0!3m2!1sen!2sin!4v1670391399930!5m2!1sen!2sin"
                        width="150"
                        height="80"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </Form>
        </section>
      </main>
    </div>
  );
}
