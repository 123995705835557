import React, { Component } from "react";


import "./Flipping.css";
const Flipping= () => (
<main className="flip">
  
  <h2 data-splitting class="headline headline--flip">flipping</h2>

</main>
);

export default Flipping;
