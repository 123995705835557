import React, { Component } from "react";

import img42 from "../images/img42.jpg";
import img47 from "../images/img47.jpg";
import img48 from "../images/img48.jpg";
import "./Aboutpart14.css";
const Aboutpart14 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart14-page"
      id="nature"
    >
      <section className="Aboutpart14-page container">
        <h2 className="col-md-9 pt-5  text-end">KNEE</h2>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1 px-md-3">
            <div className="imp-page">
              <h1>Meniscal Tear</h1>
              <p className="mt-5">
                The Meniscus is a cartilage block that attaches onto the top of
                the tibia plateau and makes contact with the femur. The meniscus
                cushions the joint and acts as a shock absorber.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img42}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1 px-md-3">
            <div className="imp-page">
              <h1>Patella Femoral Syndrome</h1>
              <p className="mt-5">
                The patella is held in place by the quadriceps muscles and it
                also attaches to the tibia via the patella ligament (aka as the
                patella tendon).
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img47}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1 px-md-3">
            <div className="imp-page">
              <h1>Damage to Anterior Cruciate Ligament</h1>
              <p className="mt-5">
                This ligament is connected from the medial horn of the medial
                meniscus and passes superior and laterally to the lateral
                femoral condyle. The ACL is placed in the center of the knee and
                provides major stability for the knee.{" "}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img48}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart14;
