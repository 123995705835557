import React, { Component, useState,useEffect } from "react";
import "../App.css";

import ServicesHero3 from "./ServicesHero3/ServicesHero3";

import Aboutpart9 from "./Aboutpart9/Aboutpart9";
import Aboutpart10 from "./Aboutpart10/Aboutpart10";
import Aboutpart11 from "./Aboutpart11/Aboutpart11";
import Aboutpart12 from "./Aboutpart12/Aboutpart12";
import Aboutpart13 from "./Aboutpart13/Aboutpart13";
import Aboutpart14 from "./Aboutpart14/Aboutpart14";
import Aboutpart15 from "./Aboutpart15/Aboutpart15";
import Aboutpart16 from "./Aboutpart16/Aboutpart16";
export default function Condition3({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
 },[])
  return (
    <div id="Services3">
      <ServicesHero3 />
      <Aboutpart9 />
      <Aboutpart10 />
      <Aboutpart11 />
      <Aboutpart12 />

      <Aboutpart13 />

      <Aboutpart14 />
      <Aboutpart15/>
      <Aboutpart16/>
    </div>
  );
}
