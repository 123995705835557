import React, { Component } from "react";

import img46 from "../images/img46.jpg";
import img44 from "../images/img44.jpg";
import img45 from "../images/img45.jpg";
import "./Aboutpart16.css";
const Aboutpart16 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart16-page"
      id="nature"
    >
      <section className="Aboutpart16-page container">
        <h2 className="col-md-9 pt-5  text-end">SHOULDER</h2>
        <div className="d-md-flex   align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1">
            <br></br>

            <div className="imp-page px-md-3">
              <h1>Rotator Cuff Tear</h1>
              <p className="mt-5">
                There are four muscles that make up the rotator cuff. The
                muscles that make up the rotator cuff are the subscapularis,
                supraspinatus, infraspinatus and the teres minor.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img46}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1">
            <br></br>

            <div className="imp-page px-md-3">
              <h1> Frozen Shoulder</h1>
              <p className="mt-5">
                The shoulder joint is made up of the humerus and the glenoid
                fossa. There are several muscles that surround this joint and
                affect the way that the joint moves.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img44}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-12 col-md-4">
            <h1> Shoulder Impingement Syndrome</h1>
            <p className="mt-5">
              The four muscles that make up the rotator cuff attach to the head
              of the humerus. These muscles stabilize the shoulder and work
              together to lift and internally and externally rotate the
              shoulder.
            </p>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img45}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart16;
