import React, { Component } from "react";

import img40 from "../images/img40.jpg";
import img56 from "../images/img56.jpg";
import img57 from "../images/img57.jpg";
import "./Aboutpart12.css";
const Aboutpart12 = () => (
  <div>
    <main
      data-aos="fade-up"
      data-aos-easing="ease-in-sine"
      data-aos-delay="100"
      className="Aboutpart12-page"
      id="nature"
    >
      <section className="Aboutpart12-page container">
        <h2 className="col-md-9 pt-5  text-end">HIP</h2>
        <div className="d-md-flex  align-items-center justify-content-center">
          <div className="col-12 col-md-4 m-1">
            <div className="imp-page px-md-3">
              <h1>Bursitis</h1>
              <p className="mt-5">
                Bursitis is inflammation of a bursa. Bursa is a fluid filled sac
                which acts as a cushion between tendons, bones, and skin.
                Bursitis at the hip is inflammation to the bursa that lies on
                top of the greater trochanter.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8 mx-1 super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img40}
            ></img>
          </div>
        </div>
        <div className="d-md-flex align-items-center justify-content-center pt-5">
          <div className="col-12 col-md-4 m-1">
            <br></br>

            <div className="imp-page px-md-3">
              <h1> Groin Strain Treatment</h1>
              <p className="mt-5">
                A strain is the tearing or stretching of muscle fibers. When
                someone strains the groin muscles, they typically are straining
                the hip adductors (the muscles that bring your legs back towards
                your midline).
              </p>
            </div>
          </div>
          <div className="col-12 col-md-8  super3 px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img56}
            ></img>
          </div>
        </div>
        <div className="d-md-flex  align-items-center justify-content-center pt-5">
          <div className="imp-page px-md-3 col-md-4">
            <h1>Fractures</h1>
            <p className="mt-5">
              Fractures are full or partial breaks in a bone. Those that exist
              in the foot or ankle can be commonly recognized and treated
              together. They are also common in the tibia and fibula, as
              mentioned above, in addition to the calcaneus (heel).
            </p>
          </div>
          <div className="col-12 col-md-8 mx-1 super3  px-md-3">
            <img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              data-aos-delay="200"
              className="img-fluid prop"
              src={img57}
            ></img>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default Aboutpart12;
