import React, { Component, useState } from "react";
import "../App.css";
import { Modal } from "antd";
import Hero from "./Hero/Hero";
import About from "./About/About";
import Weare from "./Weare/Weare";
// import Flipping from "./Flipping/Flipping";
import Services2 from "./Services2/Services2";
import ContactUs from "./ContactUs/ContactUs";
import Conditions2 from "./Conditions2/Conditions2";
export default function Home({
  setNewMessage,
  setModalVisible,
  newValues,
  setNewValues,
}) {
  return (
    <div id="home">
      <Hero
        setNewMessage={setNewMessage}
        setModalVisible={setModalVisible}
        newValues={newValues}
        setNewValues={setNewValues}
      /> 
      
      <About />
      {/* <Flipping/> */}
      <Weare />
      
      <Conditions2 />
      <Services2 />
      <ContactUs />
    
    </div>
  );
}
