import React, { Component } from "react";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import img16 from "../images/img16.jpg";
import img17 from "../images/img17.jpg";
import img18 from "../images/img18.jpg";
import img3 from "../images/img3.png";
import img15 from "../images/img15.jpg";
// import Ani1 from "../Ani1/Ani1";
import img32 from "../images/img32.png";
import img34 from "../images/img34.png";
import { Form, form, Input, Button, Drawer, DatePicker } from "antd";
import "./Whoarewe1.css";

const Whoarewe1 = ({ setNewMessage, setModalVisible }) => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const PRIMARY_API =
    "https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    form.resetFields();
    var adminVerifyRaw = JSON.stringify({
      appName: "TRAD_TRA_DEV",
      modName: "CREATE_APPOINTMENT",
      attributeName: "Chauffeur",
      attributeValue: [values],
    });

    var adminVerifyRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: adminVerifyRaw,
      redirect: "follow",
    };
    fetch(`${PRIMARY_API}/general`, adminVerifyRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          navigate("/Success");
          // window.location.href = "/Success";
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <main className="side-Whoarewe1-page" id="nature">
        <section className="Whoarewe1-page container">
          <div className="d-lg-flex justify-content-center">
            <div className="col-12 col-lg-6 mx-5 ">
              <h3>LOWER BODY / UPPER BODY COMPRESSION THERAPY</h3>
              <p>
                Compression therapy reduces swelling in the event of an injury,
                improves recovery from strenuous workouts and reduces the risk
                of delayed onset muscle soreness. By alternating zones of
                compression, the boots and sleeves create a flushing effect to
                help the body clear lactic acid and other inflammation to reduce
                swelling and fatigue after a workout, while speeding up recovery
                time.
              </p>
            </div>
            <div className="Whoarewe1-img  m-md-4 ">
              <img
                className=" col-12 col-lg-6 m-1 img-fluid mt-5"
                data-aos="fade-up"
                data-aos-delay="200"
                src={img34}
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Whoarewe1;
