import "./SuccessComponent.css";
import {useEffect} from 'react'
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function SuccessComponent({ newValues }) {
  const navigate = useNavigate();
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' })
 },[])
  return (
    // <div className="d-flex justify-content-center align-items-center">
    //   Confirm Details
    //   {<div>{console.log(newValues)}</div>}
    // </div>
    <div className=" content-color align-items-center justify-content-center success-btn">
      <Result
        status="success"
        title="Thank you, Your request has been received"
        subTitle="Our representative will get in touch with you shortly."
        extra={[
          <Button
            className="success-btn"
            type="primary"
            key="console"
            onClick={() => navigate("/")}
          >
            Back to Home
          </Button>,
          // <Button onClick={() => navigate("/")}>Cancel</Button>,
        ]}
      />
    </div>
  );
}
